import React from 'react';
import './styles.scss';
import { Section } from '../Section';
import { PostFigure } from '../PostFigure';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PostsContext } from '../../context-providers/PostsProvider';
import { SkeletonLoader } from '../SkeletonLoader';

const SIZES = ['large', 'small', 'small', 'small', 'medium', 'medium'];

export const Posts = () => {
  const { posts, loadMore, hasMore } = React.useContext(PostsContext);

  const getFigureSize = index => SIZES[index % SIZES.length];

  const singleFigureLoader = <SkeletonLoader height={300} />;

  return (
    <Section id="blog-posts">
      <InfiniteScroll
        className="posts-wrapper"
        dataLength={posts.length}
        next={loadMore}
        hasMore={hasMore}
        loader={
          <>
            {singleFigureLoader}
            {singleFigureLoader}
            {singleFigureLoader}
          </>
        }
      >
        {posts.map(({ node: post }, index) => {
          let size = getFigureSize(index);

          const isSingleMediumFigure =
            getFigureSize(index) === 'medium' &&
            getFigureSize(index - 1) === 'small' &&
            !posts[index + 1];

          const isSingleSmallFigure =
            getFigureSize(index) === 'small' &&
            getFigureSize(index - 1) === 'large' &&
            !posts[index + 1];

          if (isSingleMediumFigure || isSingleSmallFigure) {
            size = 'large';
          }

          return (
            <PostFigure
              key={index}
              image={post.frontmatter.thumbnail}
              title={post.frontmatter.title}
              description={post.internal.content}
              date={post.frontmatter.date}
              link={`/blog/${post.frontmatter.path}`}
              size={size}
            />
          );
        })}
      </InfiniteScroll>
    </Section>
  );
};
