import React from 'react';
import { Layout } from '../components/Layout';
import { Posts } from '../components/Posts';
import muiTheme from '../utils/muiTheme';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import SEO from '../components/seo';

const Blog = () => (
  <ThemeProvider theme={muiTheme}>
    <Layout isShifted>
      <SEO />
      <Posts />
    </Layout>
  </ThemeProvider>
);

export default Blog;
